<template>
    <div class="text-center d-flex justify-content-center align-content-center">        
        <img :src="previewImage" class="align-self-center" style="max-width: 100%; max-height: 100%;"/>
    </div>
</template>
<script>
    
    import { socketIO } from "@/constant/config";
    const { io } = require("socket.io-client");
    var socketPreview = io(socketIO.previewSocketUrl);
    const { gzip, ungzip } = require('node-gzip');
    const toUint8Array = require('base64-to-uint8array')
    export default {
        props: {
            computerName: {
                type: String,
                default: '',
            }
        },
        data() {
            return {
                previewImage: '/img/no-data-1.jpg'
            };
        },
        mounted() {
            console.log("computerName", this.computerName);
            this.startStream();            
        },        
        beforeDestroy() {
            socketPreview.off(socketIO.IRENDER_MSG_RENDERLOG);
            this.previewImage = `/img/no-data-1.jpg`;
        },
        watch: {
            computerName(newVal) {
                console.log("computerName", this.computerName);
                this.startStream();
            }
        },
        methods: {
            startStream() {
                if (this.computerName !== null) {
                    this.previewImage = `/img/no-data-1.jpg`;
                    socketPreview.disconnect();
                    socketPreview = io(socketIO.previewSocketUrl);
                    console.log("this.computerName", this.computerName);
                    socketPreview.on("connect", () => {
                        console.log(`Connected Socket IO ${this.computerName}`);
                        socketPreview.emit(socketIO.IRENDER_CMD_ADDUSER, this.computerName);
                    });
                    socketPreview.on(socketIO.IRENDER_JOB_PREVIEW_TOPIC, (payload) => {
                        //console.log("payload.frame", payload.filePath);

                        let stringData = payload.filePath;
                        let buffer = toUint8Array(stringData)
                        buffer = buffer.slice(4);
                        ungzip(buffer)
                            .then((decompressed) => {
                                this.previewImage = `data:image/jpeg;base64,${decompressed.toString()}`;
                            });
                    });
                }
            }
        }
    }
</script>